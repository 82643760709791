.react-calendar {
  border: unset;
  background-color: transparent;
  font-family: 'Open+Sans', sans-serif;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation {
  align-items: center;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  text-decoration: unset !important;
}

.react-calendar__navigation__prev-button {
  background-color: var(--chakra-colors-brand-500) !important;
  border-radius: 10px;
  min-width: 34px !important;
  height: 34px !important;
  color: white;
}

.react-calendar__navigation__next-button {
  background-color: var(--chakra-colors-brand-500) !important;
  border-radius: 10px;
  min-width: 34px !important;
  width: 34px !important;
  height: 34px !important;
  color: white;
}

.react-calendar__navigation__label {
  font-weight: 700 !important;
  font-size: 18px;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus {
  background-color: unset !important;
  border-radius: 10px;
}

.react-calendar__tile {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  height: 34px !important;
  border-radius: 10px;
  color: var(--chakra-colors-secondaryGray-900);
}

.react-calendar__month-view__weekdays {
  background-color: var(--chakra-colors-secondaryGray-300);
  border-radius: 10px;
  margin-bottom: 6px;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: var(--chakra-colors-secondaryGray-300);
  border-radius: 10px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--chakra-colors-secondaryGray-500);
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--chakra-colors-brand-500);
  border-radius: 10px;
  color: white;
}

.react-calendar__tile--range,
.react-calendar__tile--range:enabled:hover,
.react-calendar__tile--range:enabled:focus {
  background: var(--chakra-colors-secondaryGray-300);
  color: var(--chakra-colors-brand-500);
  border-radius: 0px;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:enabled:hover,
.react-calendar__tile--rangeStart:enabled:focus {
  background: var(--chakra-colors-brand-500);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
}

.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar__tile--rangeEnd:enabled:focus {
  background: var(--chakra-colors-brand-500);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
}

/* Disabled Dates */
.react-calendar__tile--disabled,
.react-calendar__tile:disabled {
  opacity: 0.2;
  background: transparent;
  cursor: not-allowed;
  pointer-events: none;
}

.react-calendar__tile--disabled:hover,
.react-calendar__tile:disabled:hover {
  cursor: not-allowed;
}

/* DARK MODE */
body.chakra-ui-dark .react-calendar {
  border-radius: 30px;
}

body.chakra-ui-dark .react-calendar__navigation__prev-button {
  background-color: var(--chakra-colors-brandScheme-200) !important;
}

body.chakra-ui-dark .react-calendar__navigation__next-button {
  background-color: var(--chakra-colors-brandScheme-200) !important;
  color: white;
}

body.chakra-ui-dark .react-calendar__tile {
  color: white;
}

body.chakra-ui-dark .react-calendar__tile:enabled:hover,
body.chakra-ui-dark .react-calendar__tile:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

body.chakra-ui-dark .react-calendar__month-view__weekdays {
  background-color: rgba(255, 255, 255, 0.1);
}

body.chakra-ui-dark .react-calendar__tile--now,
body.chakra-ui-dark .react-calendar__tile--now:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--now:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

body.chakra-ui-dark .react-calendar__month-view__days__day--neighboringMonth {
  color: var(--chakra-colors-secondaryGray-500);
}

body.chakra-ui-dark .react-calendar__tile--active,
body.chakra-ui-dark .react-calendar__tile--active:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--active:enabled:focus {
  background: var(--chakra-colors-brandScheme-200);
  color: white;
}

body.chakra-ui-dark .react-calendar__tile--range,
body.chakra-ui-dark .react-calendar__tile--range:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--range:enabled:focus {
  background: rgba(255, 255, 255, 0.1);
  color: var(--chakra-colors-brandScheme-200);
}

body.chakra-ui-dark .react-calendar__tile--rangeStart,
body.chakra-ui-dark .react-calendar__tile--rangeStart:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--rangeStart:enabled:focus {
  background: var(--chakra-colors-brandScheme-200);
  color: white;
}

body.chakra-ui-dark .react-calendar__tile--rangeEnd,
body.chakra-ui-dark .react-calendar__tile--rangeEnd:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--rangeEnd:enabled:focus {
  background: var(--chakra-colors-brandScheme-200);
  color: white;
}

body.chakra-ui-dark .react-calendar__month-view__days__day.react-calendar__tile--hover {
  background-color: var(--chakra-colors-secondaryGray-700);
  border-radius: 0;
  color: white;
}
